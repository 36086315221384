<template>
  <div>
    <CrudRead
      v-bind="this.$store.state.stone_model.shop_product_cover_frame"
      @title-bar-custom-btn-click="$_titleBarCustomBtnClick($event)"
      @input="$_onReadInput($event)"
    ></CrudRead>

    <WsIndexBelongsToMany
      :belongsToManyModelName="$store.state.stone_model.shop_product.modelName"
      :belongsToManyFields="$store.state.stone_model.shop_product.fields"
      :belongsToManyShowFields="belongsToMany.showFields"
      :belongsToManyPickShowFields="belongsToMany.pickShowFields"
      :belongsToManyPickFilterSelects="_belongsToManyPickFilterSelects"
      :modelName="$store.state.stone_model.shop_product_cover_frame.modelName"
      :id="$route.params.id"
      paramsKey="shop_product_cover_frame"
      updateBtnLabel="管理商品"
      label="商品"
    ></WsIndexBelongsToMany>

    <!-- <WsMain>
      <WsCard>
        <WsCrud
          modelName="shop_product"
          :label="$t('活動商品')"
          :fields="shopProduct.fields"
          :showFields="shopProduct.showFields"
          :infiniteScroll="true"
          :inRowBtnDelete="false"
          :inRowBtnUpdate="false"
          :inRowBtnRead="true"
          :showExpand="false"
          :expandable="false"
          :filterUniqueSection="true"
          :deletable="false"
          :creatable="false"
          :selectable="false"
          :params="_shopProductParams"
          :titleBarCustomBtns="titleBarCustomBtns"
          @title-bar-custom-btn-click="$_indexTitleBarCustomBtnClick($event)"
          :value="state"
          @input="$_onInput($event)"
        > </WsCrud>
      </WsCard>
    </WsMain>
    <WsModelPicker
      ref="indexDialog"
      modelName="shop_product"
      :showFields="shopProductUpdate.showFields"
      :fields="shopProductUpdate.fields"
      valueSync
      :params="_shopProductDialogParams"
      :filterSelects="shopProductUpdate.filterSelects"
      :value="selected"
      @submit="$_submitProductUpdate($event)"
    ></WsModelPicker> -->
    <WsPopupAlert
      ref="DeletePopup"
      :title="$t('確定要刪除此圖框嗎？')"
      @submit="$_delete($event)"
      :submitBtnText="$t('確定')"
    ></WsPopupAlert>
  </div>
</template>

<script>
import model from "@/__vue2stone_cms/models/shop_product";
export default {
  data() {
    return {
      belongsToMany: {
        showFields: ["no", "name", "spec"],
        pickShowFields: ["no", "name", "spec", "is_active", "price"],
      },
      readState: {},
      state: [],
      selected: [],
      titleBarCustomBtns: [
        {
          label: this.$t("管理商品"),
          event: "product-dialog",
          icon: {
            name: "icon-md-edit",
          },
        },
      ],
      shopProduct: {
        showFields: ["no", "name", "shop_classes", "shop_subclasses", "spec"],
        fields: model.fields,
      },
      shopProductUpdate: {
        showFields: [
          "name",
          "spec",
          "is_active",
          "shop_product_cover_frame_duration",
        ],
        fields: model.fields,
        filterSelects: {
          shop_shop_class: {
            label: this.$t("篩選商品分類"),
            type: "belongs-to-many-multi-layers",
            layerFields: [
              {
                modelName: "shop_class",
                stateKey: "shop_classes",
                modelKey: "shop_classes",
              },
              {
                modelName: "shop_subclass",
                stateKey: "shop_subclasses",
                modelKey: "shop_subclasses",
              },
            ],
          },
        },
      },
    };
  },
  computed: {
    _belongsToManyPickFilterSelects() {
      const _belongsToManyPickFilterSelects = {};
      _belongsToManyPickFilterSelects.featured_classes =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.featured_classes;
      _belongsToManyPickFilterSelects.shop_classes =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.shop_classes;
      _belongsToManyPickFilterSelects.shop_subclasses =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.shop_subclasses;
      _belongsToManyPickFilterSelects.is_active =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.is_active;
      return _belongsToManyPickFilterSelects;
    },
    _id() {
      return this.$route.params.id;
    },
    _shopProductParams() {
      return { shop_product_cover_frame: this._id };
    },
    _shopProductDialogParams() {
      if (this.readState && this.readState.order_type) {
        return { order_type: this.readState.order_type };
      }
      return {};
    },
  },
  methods: {
    async $_titleBarCustomBtnClick($event) {
      if ($event == "update-template") {
        this.$router.push(`/shop_product_cover_frame/${this._id}/update`);
      }
      if ($event == "delete-template") {
        this.$_deletePopupOpen();
      }
    },
    $_deletePopupOpen(data) {
      this.$refs.DeletePopup.open(data);
    },
    $_deletePopupClose() {
      this.$refs.DeletePopup.close();
    },
    async $_delete() {
      try {
        this.$_deletePopupClose();
        await this.$axios.delete(`/shop_product_cover_frame/${this._id}`);
        await this.$router.push(`/_point`);
      } catch (err) {
        alert(err);
      }
    },
    $_IndexDialogOpen() {
      this.$refs.indexDialog.open();
    },
    $_IndexDialogClose() {
      this.$refs.indexDialog.close();
    },
    $_indexTitleBarCustomBtnClick($event) {
      if ($event === "product-dialog") {
        this.$_IndexDialogOpen();
      }
    },
    $_onUpdateSelected($event) {
      this.selected = $event;
    },
    async $_submitProductUpdate($event) {
      this.$_onUpdateSelected($event);
      this.$_IndexDialogClose();
      if (!this.selected || !this.selected.length) return;
      let productsId = this.selected.map((item) => item.id);
      await this.$axios.patch(`/shop_product_cover_frame/${this._id}`, {
        shop_products: productsId,
      });
      this.state = [...this.selected];
    },
    $_productCrud() {
      if (this.$refs.productCrudDialog.modeldataLoading) return;
      if (
        this.$refs.productCrudDialog.lastPage >
        this.$refs.productCrudDialog.page
      ) {
        this.$refs.productCrudDialog.$_onPageto(
          this.$refs.productCrudDialog.page + 1
        );
      }
    },
    $_onInput($event) {
      this.state = $event;
      this.selected = $event;
    },
    $_onReadInput($event) {
      this.readState = $event;
    },
  },
};
</script>

<style>
</style>